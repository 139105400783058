<template>
  <div>
    
    <!-- Organization Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Organizationname -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Байгууллагын нэр"
            label-for="organization-name"
          >
            <b-form-input
              id="organization-name"
              v-model="organizationData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Organizationphone -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Утас"
            label-for="organization-phone"
          >
            <b-form-input
              id="organization-phone"
              v-model="organizationData.phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Organizationaddress -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Хаяг"
            label-for="organization-address"
          >
            <b-form-textarea 
              id="organization-address"
              v-model="organizationData.address"
              autofocus
              trim
              rows="3"
              placeholder="Хаяг"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Organizationtimetable -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Цагийн хуюаарь"
            label-for="organization-timetable"
          >
            <quill-editor
              id="quil-content"
              v-model="organizationData.timetable"
              autofocus
              :options="editorOption"
              class="border-bottom-0"
            />
            <div
              id="quill-toolbar-sidebar"
              class="d-flex justify-content-end border-top-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
            </div>
          </b-form-group>
        </b-col>

        <!-- Field: Organizationdescription -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Нэмэлт тайлбар"
            label-for="organization-description"
          >
            <b-form-textarea 
              id="organization-description"
              v-model="organizationData.description"
              autofocus
              trim
              rows="3"
              placeholder="Нэмэлт тайлбар"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Байгууллагын код"
            label-for="organization-code"
          >
            <b-form-input
              id="organization-code"
              v-model="organizationData.code"
            />
          </b-form-group>
        </b-col> -->

        
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Дараалал"
            label-for="sort-order"
          >
            <b-form-spinbutton
                id="sort-order"
                v-model="organizationData.sort_order"
                min="1"
                max="100"
              />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <!-- <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card> -->
    <organization-view-organization-teams-card :organization-data="organizationData" :action="true"/>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="editOrganization()"
    >
      Хадгалах
    </b-button>
    <b-button
      variant="outline-secondary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"

      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Цуцлах
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormSpinbutton, BFormTextarea
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useOrganizationsList from '../organizations-list/useOrganizationsList'
import OrganizationViewOrganizationTeamsCard from '../organizations-view/OrganizationViewOrganizationTeamsCard.vue'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSpinbutton,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    quillEditor,
    OrganizationViewOrganizationTeamsCard,
  },
  props: {
    organizationData: {
      type: Object,
      required: true,
    },
  },
  mounted () {
    this.checkOrganizationData = true
  },
  setup(props) {
    const { resolveOrganizationRoleVariant } = useOrganizationsList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Organization',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.organizationData.avatar = base64
    })

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar-sidebar',
      },
      placeholder: 'Цагийн хуваарь',
    }

    return {
      resolveOrganizationRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      editorOption
    }
  },
  data () {
    return {
      checkOrganizationData: false
    }
  },
  methods: {
    editOrganization () {
      store.dispatch('app-organization/editOrganization', this.organizationData)
      .then(response => {
        if (response.data.success){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'organizations-list' })
        } else {
          // This block handles unsuccessful login attempts
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar-sidebar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
