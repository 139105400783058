<template>
  <component :is="organizationData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="organizationData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching organization data
      </h4>
      <div class="alert-body">
        No organization found with this organization id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-organizations-list'}"
        >
          Organization List
        </b-link>
        for other organizations.
      </div>
    </b-alert>

    <b-tabs
      v-if="organizationData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="OrganizationIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Ерөнхий мэдээлэл</span>
        </template>
        <organization-edit-tab-account
          :organization-data="organizationData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Дэлгэрэнгүй мэдээлэл</span>
        </template>
        <organization-edit-tab-information class="mt-2 pt-75" />
      </b-tab> -->

      <!-- Tab: Social -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Нийгмийн сүлжээ</span>
        </template>
        <organization-edit-tab-social class="mt-2 pt-75" />
      </b-tab> -->
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import organizationStoreModule from '../organizationStoreModule'
import OrganizationEditTabAccount from './OrganizationEditTabAccount.vue'
import OrganizationEditTabInformation from './OrganizationEditTabInformation.vue'
import OrganizationEditTabSocial from './OrganizationEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    OrganizationEditTabAccount,
    OrganizationEditTabInformation,
    OrganizationEditTabSocial,
  },
  setup() {
    const organizationData = ref(null)

    const ORGANIZATION_APP_STORE_MODULE_NAME = 'app-organization'

    // Register module
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME)) store.registerModule(ORGANIZATION_APP_STORE_MODULE_NAME, organizationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME)) store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-organization/fetchOrganization', { id: router.currentRoute.params.id })
      .then(response => { organizationData.value = response.data.response })
      .catch(error => {
        if (error.response.status === 404) {
          organizationData.value = undefined
        }
      })

    return {
      organizationData,
    }
  },
}
</script>

<style>

</style>
